import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider, useRouteError, Outlet } from "react-router-dom";
import Login from "./Login";
import MagicLogin from "./components/MagicLogin";
import Admin from "./Admin";
import Map from "./Map";
import UploadPage from "./UploadPage";
import Other from "./Other";
import Privacy from "./Privacy";
import Stats from "./Stats";
import ItemViewPage from "./ItemViewPage";
import TasksView from "./TasksView";
import Onboarding from "./bamboo-components/Onboarding";
import Feed from "./Feed";
import SearchPage from "./SearchPage";
import Header from "./components/Header";
import UserSettingsManager from "./components/UserSettingsManager";
import Users from "./Users";
import GlobalContext from "./GlobalContext";
import Sidebar from "./components/Sidebar";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

const APP_BASE_URL = process.env.REACT_APP_GRAPHQL_BASE_URL;

const httpLink = createHttpLink({
  uri: APP_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  uri: APP_BASE_URL,
  cache: new InMemoryCache(),
});

const colorPalette = [
  "#CAFFBF",
  "#FFD6A5",
  "#FDFFB6",
];

const backgroundColor = colorPalette[Math.floor(Math.random() * colorPalette.length)];

const ErrorBoundary = () => {
  const error = useRouteError();
  return (
    <div style={{
      padding: '20px',
      backgroundColor: '#FEE',
      color: '#333',
      fontFamily: 'monospace',
      whiteSpace: 'pre-wrap',
      overflowX: 'auto'
    }}>
      <h1 style={{ color: '#C00' }}>An error occurred</h1>
      <h2>{error.message || error.statusText}</h2>
      <pre>{error.stack}</pre>
    </div>
  );
};

const Layout = ({ isLoggedIn, setSidebarOpen, isSidebarOpen, backgroundColor }) => {
  return (
    <>
      <Header
        setSidebarOpen={setSidebarOpen}
        isLoggedIn={isLoggedIn}
        backgroundColor={backgroundColor}
      />
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
      <Outlet />
    </>
  );
};

export default function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userSettings, setUserSettings] = useState({
    userId: 200000,
    showFineGrainedRatingsInStudyView: true,
    hideRatingsInMostPlaces: false,
    darkModeEnabled: true,
  });

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem("token");
      setIsLoggedIn(!!token);
    };

    window.addEventListener("storage", checkLoginStatus);
    checkLoginStatus();

    return () => {
      window.removeEventListener("storage", checkLoginStatus);
    };
  }, []);

  const handleLogoutClick = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout isLoggedIn={isLoggedIn} setSidebarOpen={setSidebarOpen} isSidebarOpen={isSidebarOpen} backgroundColor={backgroundColor} />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: isLoggedIn ? <ItemViewPage isLoggedIn={isLoggedIn} topLevel={false} /> : <Onboarding />,
        },
        {
          path: "login",
          element: <Login onLogin={() => setIsLoggedIn(true)} />,
        },
        {
          path: "magic_login",
          element: <MagicLogin />,
        },
        {
          path: "item/",
          element: <ItemViewPage isLoggedIn={isLoggedIn} topLevel={false} />,
        },
        {
          path: "admin",
          element: <Admin />,
        },
        {
          path: "feed",
          element: <Feed isLoggedIn={isLoggedIn} purpose={"study"} />,
        },
        {
          path: "stats",
          element: <Stats />,
        },
        {
          path: "other",
          element: <Other isLoggedIn={isLoggedIn} handleLogoutClick={handleLogoutClick} userSettings={userSettings} />,
        },
        {
          path: "upload",
          element: <UploadPage />,
        },
        {
          path: "news",
          element: <Feed isLoggedIn={isLoggedIn} purpose={"news"} />,
        },
        {
          path: "map",
          element: <Map isLoggedIn={isLoggedIn} />,
        },
        {
          path: "tasks",
          element: <TasksView />,
        },
        {
          path: "search",
          element: <SearchPage />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "about",
          element: <Onboarding />,
        },
        {
          path: "privacy",
          element: <Privacy />,
        },
      ],
    },
  ]);

  return (
    <GlobalContext.Provider value={{ userSettings, setUserSettings }}>
      <ApolloProvider client={client}>
        <UserSettingsManager setUserSettings={setUserSettings} />
        <RouterProvider router={router} />
      </ApolloProvider>
    </GlobalContext.Provider>
  );
}
