import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ParentItemsManager from "./ParentItemsManager";
import ItemRatingInner from "./ItemRatingInner";
import DeleteItem from "./DeleteItem";
import StudyDetails from "./StudyDetails";
import ChildrenViaGptManager from "./ChildrenViaGptManager";
import DeleteChildren from "./DeleteChildren";
import ItemAccessSwitch from "./ItemAccessSwitch";
import GenerateQuizQuestionButton from "./GenerateQuizQuestionButton";
import GenerateSummaryButton from "./GenerateSummaryButton";
import PerformActionOnItemButton from "./PerformActionOnItemButton";

function ItemControlPanel({
  item,
  refetch,
  saveItemField,
  setAccessIsUnsaved,
  publicView,
  onItemClick,
  studyMode,
  userSettings,
  initiallyShowStudyDetails,
}) {
  const navigate = useNavigate();
  const [showPrompt2Kids, setShowPrompt2Kids] = useState(false);
  const [showStudyDetails, setShowStudyDetails] = useState(
    initiallyShowStudyDetails
  );
  return (
    <div
      style={{
        marginLeft: "5px",
      }}
    >
      {!(studyMode && userSettings.showFineGrainedRatingsInStudyView) ? (
        <div>
          <div
            style={{
              marginBottom: "17px",
            }}
            className="flex-container"
          >
            {/* <div
              className="circle-button-outer"
              onClick={() => setShowPrompt2Kids((prev) => !prev)}
            >
              <div
                className={"circle-button-inner"} // pretty sure this just gives it the appearance of that grey dot
                style={{
                  backgroundColor: "#AEC6CF", // A soft pastel blue
                  // backgroundColor: "#888888", // gray
                }}
              ></div>
            </div> */}
            <div
              className="circle-button-outer"
              onClick={() => setShowStudyDetails((prev) => !prev)}
            >
              <div
                style={{
                  // backgroundColor: "#888888", // gray
                  backgroundColor: "#CDB4DB", // A gentle pastel lavender
                }}
                className={`circle-button-inner`}
              ></div>
            </div>
          </div>
          {showPrompt2Kids && item ? (
            <ChildrenViaGptManager
              item={item}
              throwTheNewKidsIntoThisFunction={(newKids) => {
                refetch();
                console.log(
                  "gotta figure out what to do with these kids",
                  newKids
                );
              }}
            />
          ) : (
            <></>
          )}
          {showStudyDetails && item ? (
            <div className="m-3">
              <StudyDetails item={item} />
            </div>
          ) : (
            <></>
          )}
          <ItemRatingInner
            rating_type_for_display={"importance"}
            rating_type={"importance"}
            item_id={item.id}
            existing_rating={item.importance}
          />
          <ItemRatingInner
            rating_type_for_display={"recall"}
            rating_type={"recall"}
            item_id={item.id}
            existing_rating={null}
            updateRatingCallback={() => null}
          />
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          marginTop: "17px",
          marginBottom: "17px",
        }}
      >
        <ParentItemsManager item={item} onItemClick={onItemClick} />
      </div>
      {!publicView ? (
        <div
          style={{
            marginTop: "17px",
            marginBottom: "17px",
          }}
        >
          <ItemAccessSwitch
            item={item}
            saveItemField={saveItemField}
            setAccessIsUnsaved={setAccessIsUnsaved}
          />
        </div>
      ) : (
        <></>
      )}
      {/* <div>type: {item.type}</div> */}
      {/* <button style={{ marginRight: "10px" }}>
        <Link to={`/feed?item_id=${item.id}`}>study item</Link>
      </button> */}

      <div
        style={{
          marginBottom: "27px",
          marginLeft: "7px",
        }}
      >
        <button
          onClick={() => {
            if (typeof onItemClick === "function") {
              onItemClick(item.id);
            } else {
              // Navigate programmatically
              navigate(`/item?item_id=${item.id}`);

              // Force a page reload
              window.location.reload();
            }
          }}
          className="control-panel-button-rectangular"
        >
          go to item
        </button>
        {!publicView ? (
          <>
            {/* {item.quizQuestion != null ? (
              <></>
            ) : (
              <GenerateQuizQuestionButton item={item} />
            )}
            {item.summary == null && <GenerateSummaryButton item={item} />}
            <PerformActionOnItemButton
              item={item}
              displayText={"generate friend question"}
              action={"generate_friend_question"}
            />
            <PerformActionOnItemButton
              item={item}
              displayText={"make assistant"}
              action={"create_assistant"}
            /> */}
            <PerformActionOnItemButton
              item={item}
              displayText={"generate audio"}
              action={"generate_audio"}
            />
            {/* <PerformActionOnItemButton
              item={item}
              displayText={"expound"}
              action={"expound"}
            />
            <PerformActionOnItemButton
              item={item}
              displayText={"split into children"}
              action={"split_into_kids"}
            />
            <PerformActionOnItemButton
              item={item}
              displayText={"split into siblings"}
              action={"split_into_siblings"}
            /> */}
            <PerformActionOnItemButton
              item={item}
              displayText={"embed"}
              action={"embed"}
            />
            {/* <PerformActionOnItemButton
              item={item}
              displayText={"intelligently split into kids"}
              action={"intelligently_split_into_kids"}
            /> */}
            {/* <PerformActionOnItemButton
              item={item}
              displayText={"move text to new child"}
              action={"move_text_to_new_child"}
            /> */}
            {/* <PerformActionOnItemButton
              item={item}
              displayText={"make new language item"}
              action={"make_new_language_learning_item"}
            />
            <PerformActionOnItemButton
              item={item}
              displayText={"generate image"}
              action={"generate_image"}
            /> */}
            {/* <PerformActionOnItemButton
              item={item}
              displayText={"share"}
              action={"share_with_ngozi"}
            /> */}
            {/* <PerformActionOnItemButton
              item={item}
              displayText={"delete image"}
              action={"delete_image"}
            /> */}
            <DeleteItem
              displayText={"delete item"}
              transparentBg={false}
              item={item}
              onDelete={() => {
                alert(
                  "item successfully deleted. when you click out of this alert, you're be still here staring at your item, but trust me it's gone."
                );
              }}
            />
            {/* <DeleteChildren
              displayText={"delete all children"}
              transparentBg={false}
              item={item}
              onDelete={() => {
                alert("we deleted all them kids");
                refetch();
              }}
            /> */}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ItemControlPanel;
