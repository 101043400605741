import { set } from "date-fns";
import React, { useState } from "react";
import { Link } from "react-router-dom";

{
  /* {isContentVisible ? "bonsai" : "b"} */
}
const Header = ({ setSidebarOpen, backgroundColor }) => {
  const [isContentVisible, setIsContentVisible] = useState(true);

  return (
    <header style={{ backgroundColor, height: "50px" }}>
      {/* <div
        className="brand"
        onClick={() => setIsContentVisible(!isContentVisible)}
      >

      </div> */}

      {isContentVisible && (
        <>
          <nav>
            <ul>
              <li>
                <button
                  style={{
                    backgroundColor: "transparent",
                    position: "relative",
                    top: "1px",
                    color: "#1a1a1a",
                    fontSize: "20px", // Increase the font size here
                  }}
                  className="hamburger"
                  onClick={() => {
                    setSidebarOpen(true);
                  }}
                >
                  &#9776; {/* hamburger */}
                </button>
              </li>
              {/* <li>
                <Link to="/capture">capture</Link>
              </li> */}
              <li>
                <Link to="/item">library</Link>
              </li>
              <li>
                <Link to="/feed">study</Link>
              </li>
              <li>
                <Link id="header-map-button" to="/map">
                  map
                </Link>
              </li>
              <li>
                <Link to="/other">other</Link>
              </li>
            </ul>
          </nav>
        </>
      )}
    </header>
  );
};

export default Header;
