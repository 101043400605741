import React, { useState, useRef, useEffect, useCallback } from "react";
import ChatHistory from "./ChatHistory";
import { SEND_MESSAGE } from "../mutations";
import { useMutation, useQuery } from "@apollo/client";

const Tour = () => <div>Tour component (to be implemented)</div>;

const TourModal = ({
  onClose,
  onStartTour,
  onTour,
  endTour,
  tourId,
  location,
}) => {
  const [textInput, setTextInput] = useState("");
  const [modeOfTransport, setModeOfTransport] = useState("bike");
  const [view, setView] = useState("chat");
  const textareaRef = useRef(null);
  const [sendMessagesMutation, { loading: sendMessageLoading }] =
    useMutation(SEND_MESSAGE);
  const [messagesForChatHistory, setMessagesForChatHistory] = useState([
    {
      text: "hey there! welcome to talky mappy",
      author: "system",
      created_at: "2023-08-13T10:00:00Z",
    },
    {
      text: 'tell me what kind of tour you want OR you can just press "start tour" and we\'ll build you something standard',
      author: "system",
      created_at: "2023-08-13T10:00:00Z",
    },
  ]);

  const textareaStyle = {
    width: "calc(100% - 80px)",
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "3px",
    backgroundColor: "#f0f0f0",
    resize: "none",
    overflow: "hidden",
    minHeight: "40px",
    maxHeight: "180px",
  };

  const handleOutsideClick = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const contentStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
    width: "80%",
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
    height: "95%",
  };

  const lightGrayColor = "#8A8888";

  const buttonStyle = {
    padding: "10px 20px",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
    marginLeft: "10px",
    backgroundColor: "white",
    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
    color: lightGrayColor,
  };

  const sendButtonStyle = {
    ...buttonStyle,
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    background: "transparent",
    opacity: sendMessageLoading ? 0.5 : 1,
    cursor: sendMessageLoading ? "not-allowed" : "pointer",
  };

  const recordButtonStyle = {
    ...buttonStyle,
    marginLeft: "10px",
    height: "40px",
    width: "40px", // Changed from 30px to 40px to make it square
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0, // Remove any padding to ensure the SVG fits properly
  };

  const radioLabelStyle = {
    marginRight: "10px",
    display: "inline-flex",
    alignItems: "center",
    color: lightGrayColor,
  };

  const radioInputStyle = {
    marginRight: "5px",
    accentColor: lightGrayColor,
  };

  const switchStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const switchButtonStyle = (isActive) => ({
    padding: "10px 20px",
    border: "none",
    backgroundColor: "transparent",
    color: isActive ? "#333333" : "#CCCCCC",
    cursor: "pointer",
    fontSize: "16px",
    transition: "color 0.3s ease",
  });

  const controlGroupStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "2px",
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [textInput]);

  const handlePromptChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleSendMessage = () => {
    if (!textInput.trim() || sendMessageLoading) {
      return;
    }
    setMessagesForChatHistory((prevMessages) => [
      ...prevMessages,
      {
        text: textInput,
        author: "user",
        created_at: new Date().toISOString(),
      },
    ]);
    setTextInput("");
    sendMessagesMutation({
      variables: {
        tourId: tourId,
        message: textInput,
        lat: parseFloat(location.lat),
        lon: parseFloat(location.lon),
      },
    })
      .then((response) => {
        console.log("after send message", response);
        if (response.data.sendMessage.result == "fail/spots_item_not_found") {
          alert("send message failed bc spots item not found!");
          return;
        }
        setMessagesForChatHistory((prevMessages) => [
          ...prevMessages,
          {
            text: response.data.sendMessage.response,
            author: "system",
            created_at: "2023-08-19T10:00:00Z",
          },
        ]);
        // messagesForChatHistory.push({
        //   text: "yo",
        //   author: "system",
        //   created_at: "2023-08-19T10:00:00Z",
        // });
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        alert("Failed to send message.");
      });
  };

  const handleRecord = () => {
    alert("not implemented!");
  };

  const handleTourButtonClick = () => {
    if (onTour) {
      console.log("onTour");
      endTour();
    } else {
      console.log("not onTour");
      onStartTour(null, textInput, modeOfTransport, "");
      setTextInput("");
    }
  };

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter" && !e.shiftKey) {
  //     e.preventDefault();
  //     handleSendMessage();
  //   }
  // };

  return (
    <div style={modalStyle} onClick={handleOutsideClick}>
      <div style={contentStyle}>
        <div style={switchStyle}>
          <button
            style={switchButtonStyle(view === "chat")}
            onClick={() => setView("chat")}
          >
            chat
          </button>
          <button
            style={switchButtonStyle(view === "tour")}
            onClick={() => setView("tour")}
          >
            tour
          </button>
        </div>
        <div style={{ flex: 1, overflowY: "auto", height: "100%" }}>
          {view === "chat" ? (
            <ChatHistory messages={messagesForChatHistory} />
          ) : (
            <Tour />
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "20px",
          }}
        >
          <textarea
            ref={textareaRef}
            value={textInput}
            onChange={handlePromptChange}
            // onKeyPress={handleKeyPress}
            style={textareaStyle}
            rows="1"
          />
          <button onClick={handleRecord} style={recordButtonStyle}>
            <svg
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="8" fill="#FF0000" />
            </svg>
          </button>
          <button
            onClick={handleSendMessage}
            style={{
              ...sendButtonStyle,
              opacity: sendMessageLoading ? 0.5 : 1,
              cursor: sendMessageLoading ? "not-allowed" : "pointer",
            }}
            disabled={sendMessageLoading}
          >
            {sendMessageLoading ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ animation: "spin 1s linear infinite" }}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  fill="none"
                  stroke="#8A8888"
                  strokeWidth="2"
                  strokeDasharray="30 60"
                />
              </svg>
            ) : (
              <>
                <svg
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M22 2L11 13" />
                  <path d="M22 2L15 22L11 13L2 9L22 2Z" />
                </svg>
              </>
            )}
          </button>
        </div>
        <div style={controlGroupStyle}>
          <label style={radioLabelStyle}>
            <input
              type="radio"
              value="walk"
              checked={modeOfTransport === "walk"}
              onChange={(e) => setModeOfTransport(e.target.value)}
              style={radioInputStyle}
            />
            walk
          </label>
          <label style={radioLabelStyle}>
            <input
              type="radio"
              value="bike"
              checked={modeOfTransport === "bike"}
              onChange={(e) => setModeOfTransport(e.target.value)}
              style={radioInputStyle}
            />
            bike
          </label>
          <button
            id="start-tour-button"
            onClick={handleTourButtonClick}
            style={buttonStyle}
          >
            {onTour ? "end tour" : "start tour"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TourModal;
