import { gql } from "@apollo/client";

export const ITEM = gql`
  query ($id: String, $name: String, $isPublicView: Boolean) {
    item(id: $id, name: $name, isPublicView: $isPublicView) {
      id
      name
      text
      type
      href
      access
      isPublic
      prompt
      areaOfRelevance
      lat
      lon
      createdAt
      imageUrl
      quizQuestion
      summary
      importance
      aggregateImportance
      openaiAssistantId
      openaiFileId
      numPublicChildren
      user {
        id
      }
      parentItems {
        id
        name
        text
        isPublic
        areaOfRelevance
        lat
        lon
        importance
        type
        href
        createdAt
      }
      childItems {
        id
        name
        areaOfRelevance
        lat
        lon
        text
        type
        href
        access
        imageUrl
        openaiFileId
        numPublicChildren
        isPublic
        prompt
        createdAt
        quizQuestion
        summary
        importance
        aggregateImportance
        numPublicChildren
        user {
          id
        }
        parentItems {
          id
          name
          text
          type
          isPublic
          importance
          areaOfRelevance
          lat
          lon
          href
          createdAt
        }
        childItems {
          id
          name
          text
          href
          areaOfRelevance
          lat
          lon
          importance
          aggregateImportance
          type
          isPublic
          createdAt
          parentItems {
            id
            name
            areaOfRelevance
            lat
            lon
            text
            isPublic
            importance
            href
            createdAt
          }
          user {
            id
          }
        }
      }
    }
  }
`;

export const ITEM_SLIM = gql`
  query ($id: String, $name: String, $isPublicView: Boolean) {
    item(id: $id, name: $name, isPublicView: $isPublicView) {
      id
      name
      text
      type
      href
      access
      isPublic
      areaOfRelevance
      lat
      lon
      createdAt
      summary
      importance
      aggregateImportance
      user {
        id
      }
      childItems {
        id
        name
        text
        areaOfRelevance
        lat
        lon
        type
        href
        access
        isPublic
        createdAt
        summary
        childItems {
          id
        }
      }
    }
  }
`;

export const FEED_ITEMS = gql`
  query feed($topicItemId: String, $feedType: String, $purpose: String) {
    feed(topicItemId: $topicItemId, feedType: $feedType, purpose: $purpose) {
      overduenessScore
      numOverdueItems
      parentItem {
        id
        name
      }
      items {
        id
        name
        text
        type
        href
        access
        isPublic
        areaOfRelevance
        lat
        lon
        imageUrl
        prompt
        createdAt
        quizQuestion
        summary
        importance
        aggregateImportance
        numPublicChildren
        user {
          id
        }
        parentItems {
          id
          name
          text
          type
          isPublic
          importance
          href
          createdAt
        }
        childItems {
          id
          name
          text
          type
          href
          access
          isPublic
          prompt
          imageUrl
          createdAt
          quizQuestion
          summary
          importance
          aggregateImportance
          numPublicChildren
          user {
            id
          }
          parentItems {
            id
            name
            text
            isPublic
            importance
            type
            href
            createdAt
          }
          childItems {
            id
            name
            text
            href
            importance
            aggregateImportance
            type
            isPublic
            createdAt
            user {
              id
            }
          }
        }
      }
    }
  }
`;

export const GLOBAL_ITEMS = gql`
  query globalItems($isPublicView: Boolean!) {
    globalItems(isPublicView: $isPublicView) {
      id
      name
      text
      type
      href
      access
      imageUrl
      areaOfRelevance
      lat
      lon
      isPublic
      prompt
      createdAt
      quizQuestion
      summary
      importance
      aggregateImportance
      numPublicChildren
      user {
        id
      }
      parentItems {
        id
        name
        text
        type
        areaOfRelevance
        lat
        lon
        isPublic
        importance
        href
        createdAt
      }
      childItems {
        id
        name
        text
        type
        href
        areaOfRelevance
        lat
        lon
        access
        imageUrl
        isPublic
        prompt
        createdAt
        quizQuestion
        summary
        importance
        aggregateImportance
        numPublicChildren
        user {
          id
        }
        parentItems {
          id
          name
          text
          isPublic
          importance
          href
          areaOfRelevance
          lat
          lon
          createdAt
          type
        }
        childItems {
          id
          name
          text
          areaOfRelevance
          lat
          lon
          href
          importance
          aggregateImportance
          type
          isPublic
          createdAt
          user {
            id
          }
        }
      }
    }
  }
`;

export const SEARCH_QUERY = gql`
  query searchItems($query: String!) {
    searchItems(query: $query) {
      id
      name
      text
      type
      href
      access
      isPublic
      imageUrl
      prompt
      createdAt
      quizQuestion
      summary
      importance
      aggregateImportance
      numPublicChildren
      areaOfRelevance
      lat
      lon
      user {
        id
      }
      parentItems {
        id
        name
        text
        isPublic
        importance
        areaOfRelevance
        lat
        lon
        href
        createdAt
      }
      childItems {
        id
        name
        text
        type
        href
        access
        imageUrl
        isPublic
        prompt
        createdAt
        areaOfRelevance
        lat
        lon
        quizQuestion
        summary
        importance
        aggregateImportance
        numPublicChildren
        user {
          id
        }
        parentItems {
          id
          name
          text
          isPublic
          importance
          href
          createdAt
        }
        childItems {
          id
          name
          text
          href
          importance
          aggregateImportance
          type
          isPublic
          createdAt
          user {
            id
          }
        }
      }
    }
  }
`;

export const CHECK_FOR_IN_PROGRESS_TOUR = gql`
  query CheckForInProgressTour {
    checkForInProgressTour {
      result
      tourId
      directionsJson
      legs {
        steps {
          audioUrl
          text
          startLocation {
            lat
            lon
          }
          endLocation {
            lat
            lon
          }
        }
      }
      currentLegIndex
      currentStepIndex
    }
  }
`;

export const GET_SHIT = gql`
  query GetShit {
    getShit {
      items {
        lat
        lon
        childItems {
          id
        }
        id
        name
        text
        type
        href
        access
        isPublic
        imageUrl
        prompt
        createdAt
        quizQuestion
        summary
        importance
        aggregateImportance
        numPublicChildren
        areaOfRelevance
        lat
        lon
        user {
          id
        }
      }
    }
  }
`;
